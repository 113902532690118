<template>
  <div>
    <router-view v-if="organization" />
    <div v-else>
      <v-container fluid class="ma-10">
        <v-row align="center" justify="center">
          <v-progress-circular :size="100" :width="7" color="primary" indeterminate></v-progress-circular>
        </v-row>
      </v-container>
    </div>
    <v-container>
      <v-row>
        <v-col class="text-body-2 justify-center align-center d-flex grey--text text--darken-2 pointer"
          @click="openTerms()">
          <v-icon left small>mdi-information-outline</v-icon>
          En réservant, vous acceptez les termes et conditions</v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import PublicOrganizationService from "@/services/publicorganizationservice";

export default {
  components: {

  },
  data() {
    return {
      organization: null,
    };
  },
  computed: {
    organizationUrl() {
      return this.$route.params.organizationUrl;
    },
    billPlan() {
      return this.organization?.billPlan;
    },
  },
  async mounted() {
    // const facebookChat = document.querySelector(".fb_dialog");

    // if (facebookChat) {
    //   facebookChat.style.display = "none";
    // }

    try {
      const organization = await PublicOrganizationService.get(
        this.organizationUrl
      );

      this.organization = organization.data;
    } catch (error) {
      console.dir(error);
    }
  },
  methods: {
    openTerms() {
      window.open(`https://www.monplanning.be/${this.organizationUrl}/terms`, '_blank')
    },
  },
  metaInfo() {
    return {
      title: "monplanning",
      titleTemplate: this.$t("meta.public-organization-title", {
        organizationName: this.organization?.name,
      }),
    };
  },
};
</script>